<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
      <a-row :gutter="24" type="flex">
        <a-col :span="24" class="mb-24">
          <a-table :columns="columns" :data-source="data" :row-key="record => record.keyword" :pagination="false"
            :loading="loading">
            <template slot="editBtn" slot-scope="row">
              <div style="display: flex;">
                <a-button type="link" :data-id="row.group_name" class="edit-btn" @click="showEidt(row)">
                  编辑
                </a-button>
                <a-button type="link" :data-id="row.group_name" class="edit-btn" @click="showChart(row)">
                  查看客户详情
                </a-button>
              </div>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>
    <a-drawer
      title="客户画像"
      placement="right"
      :closable="false"
      :visible="drawerVisible"
      @close="bindCancel"
      :width="420"
    >
      <h6>客户车型</h6>
      <Pie v-if="drawerVisible" :data="carTypeData" />
      <h6 style="margin-top: 20px">客户地域分布</h6>
      <p>还没想好用什么图表展示</p>
    </a-drawer>
    <a-modal title="编辑" v-model="editUserVisible" :footer="null" :destroy-on-close="true">
      <a-row :gutter="24" type="flex">
        <a-col :span="24" :lg="4" class="mb-12">
          <div style="line-height: 40px;font-weight: 500;">真实姓名:</div>
        </a-col>
        <a-col :span="24" :lg="20" class="mb-12">
          <a-input v-model="editUser.realName" />
        </a-col>
      </a-row>
      <a-row :gutter="24" type="flex" style="margin-top: 20px;">
        <a-col :span="24" :lg="4" class="mb-12">
          <div style="line-height: 40px;font-weight: 500;">手机名:</div>
        </a-col>
        <a-col :span="24" :lg="20" class="mb-12">
          <a-input v-model="editUser.phoneName" />
        </a-col>
      </a-row>
      <a-row :gutter="24" type="flex" style="margin-top: 20px;">
        <a-col :span="24" :lg="12" class="mb-12">
        </a-col>
        <a-col :span="24" :lg="12" class="mb-12" style="text-align: right;">
          <a-button @click="bindCancel">取消</a-button>
          <a-button type="primary" style="margin-left: 12px;" @click="updateWechatManager">确定</a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { getOperationManagers, updateWechatManager } from '../http/api';
import Table from "../components/Cards/Table"
import Pie from "../components/Charts/ChartPie"

//
const columns = [{
  title: 'Id',
  dataIndex: 'id',
}, {
  title: '昵称',
  dataIndex: 'name',
}, {
  title: '别名',
  dataIndex: 'alias',
}, {
  title: '手机号',
  dataIndex: 'mobile',
}, {
  title: '客户数',
  dataIndex: 'customerCount',
}, {
  title: '真实姓名',
  dataIndex: 'realName',
}, {
  title: '手机名',
  dataIndex: 'phoneName',
}, {
  title: '操作',
  key: 'editBtn',
  scopedSlots: { customRender: 'editBtn' }
}];

const innerColumns = []

export default ({
  components: {
    Table,
    Pie
  },
  data() {
    return {
      data: [],
      columns: columns,
      innerColumns: innerColumns,
      loading: false,
      editUserVisible: false,
      editUser: {},
      drawerVisible: false,
      carTypeData: [],
    }
  },
  methods: {
    bindCancel() {
      this.editUserVisible = false
      this.editUser = {}
      this.drawerVisible = false
      this.carTypeData = []
    },
    updateWechatManager() {
      updateWechatManager({variables: this.editUser}).then(res => {
        console.log(res)
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        this.editUserVisible = false
        this.editUser = {}
        this.getOperationManagers()
      })
    },
    showChart(e) {
      console.log(e)
      let tagsStatistics = e.tagsStatistics
      let carType = tagsStatistics.car_type
      let carTypeData = []
      // 获取cartype keys
      for (let key in carType) {
        let item = {
          type: key,
          count: carType[key]
        }
        carTypeData.push(item)
      }
      this.carTypeData = carTypeData
      this.drawerVisible = true
    },
    showEidt(e) {
      console.log(e)
      this.editUserVisible = true
      this.editUser = {
        userid: e.userid,
        realName: e.realName || '',
        phoneName: e.phoneName || '',
      }
    },
    getOperationManagers() {
      getOperationManagers().then(res => {
        console.log(res)
        this.loading = false
        this.data = res.data.wxworkChat.operationManagers
      })
    },
  },
  mounted() {
    this.loading = true
    this.getOperationManagers()
  },
})

</script>

<style lang="scss"></style>