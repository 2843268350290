<template>
  <div>
    <ve-pie :data="chartData"></ve-pie>
  </div>
</template>
  
<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default ({
  props: [
    'data',
  ],
  data() {
    return {
      chartData: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.chartData = {
        columns: ['type', 'count'],
          rows: this.data
      }
    })
  },
})

</script>
  
<style lang="scss" scoped>
</style>